@media screen and (max-width: 480px) {

.BottonInput2{
    width: 16rem !important;
    margin-top: 3rem !important;
}

.InputBotton7{
    width:9rem !important;
}
.InputBotton6{
    width:9rem !important;
    margin-left: 1rem !important;
}
.ProInput{
    margin-top: 1rem !important;
}
.DriverInput{
    margin-botton: 1rem !important;
}
.CampanyInput{
    margin-top: 1rem !important;
}
.customerInput{
    margin-botton: 1rem !important;
}
}

.custom-scrollbar::-webkit-scrollbar {
    width: 12px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #FFA500; /* Cor laranja */
    border-radius: 10px;
    border: 3px solid #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #FF8C00; /* Cor laranja mais escura ao passar o mouse */
}

@media (max-width: 768px) {
    .mobile-register{
        width:22rem;
    }
}

@media (max-width: 480px) {
    .mobile-register{
        width:22rem;
    }
}


@media screen and (max-width: 768px) { /* Aumentando a largura máxima para abranger mais dispositivos */

    .mobile_register{
        margin-top:1rem !important;
    }
    }