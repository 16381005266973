@media screen and (max-width: 480px) {

.InputMobile{
    width: 17rem !important; 
}
}

@media screen and (max-width: 768px) {    



}