canvas {
    max-width: 100%;
    /* Faz o canvas ser responsivo */
    height: auto;
    /* Mantém a proporção */
    display: block;
    /* Remove espaço extra abaixo do canvas */
    margin: 0 auto;
    /* Centraliza se necessário */
}

.painel-buttons {
    display: flex;
    justify-content: center;
}

.buttons-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;

    padding-left: 2em;
    padding-right: 0.5em;
    padding-top: .5em;
    background: #ddd;
    border-radius: 45px;
    position: relative;

    border: 1px ridge #a2a2a2;
}

.avaria-button {
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* Centraliza o conteúdo verticalmente */
    outline: none;
    box-shadow: none;
    margin-left: .5em;
    min-width: 2.8em;
    /* Largura mínima fixa */
    max-width: 2.8em;
    /* Largura máxima fixa */
    height: 4em;
    /* Altura fixa */
}

.avaria-button:focus,
.avaria-button img:focus,
.undo-button:focus,
.undo-button img:focus {
    outline: none;
    box-shadow: none;
    /* Remove o foco dos botões e das imagens */
}

.avaria-button img.selected {
    border: 3px dashed #0e0404;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.8);
    border-radius: 50px;
}

.avaria-button img {
    border: 2px ridge #a2a2a2;
    border-radius: 50px;

    width: 35px;
    /* Largura fixa */
    height: 35px;
    /* Altura fixa */
    object-fit: contain;
}

.avaria-button label,
.undo-button label {
    margin-top: 5px;
    line-height: 1.2em;
    font-size: x-small;
    text-align: center;
    white-space: normal;
}

.scrollable-buttons {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    position: relative;
    box-shadow:
        inset 10px 0 5px -8px rgba(0, 0, 0, 0.4),
        /* Sombra interna à esquerda */
        inset -10px 0 5px -8px rgba(0, 0, 0, 0.4),
        /* Sombra interna à direita */
        inset 0 10px 5px -8px rgba(0, 0, 0, 0.4),
        /* Sombra interna ao topo */
        inset 0 -10px 5px -8px rgba(0, 0, 0, 0.4);

    border: 1px ridge #868686;
    
    margin-right: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 5px;
    background-color: #fff;
}

.undo-button {
    position: sticky;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* Centraliza o conteúdo verticalmente */
    outline: none;
    box-shadow: none;
    margin-top: 5px;
    margin-right: .5em;
    min-width: 2.8em;
    /* Largura mínima fixa */
    max-width: 2.8em;
    /* Largura máxima fixa */
    height: 4em;
    /* Altura fixa */

}

.undo-button img {

    border: 2px ridge #a2a2a2;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
    border-radius: 50px;

    width: 35px;
    /* Largura fixa */
    height: 35px;
    /* Altura fixa */
    object-fit: contain;
}

/* Largura da barra de scroll */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 10px;
}

/* Cor e sombra do rastilho da barra de scroll */
::-webkit-scrollbar-track {
    background: #b1b1b1;
    box-shadow: inset 0 0 0px grey;
}

/* Cor e borda do manipulador da barra de scroll */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Muda a cor do manipulador ao passar o mouse */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}