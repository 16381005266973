
@media screen and (max-width: 768px) { /* Aumentando a largura máxima para abranger mais dispositivos */

.p-10{
    padding:0.5rem !important;
}

.w-96{
    width:22rem !important;
}
}

/* Adicione este bloco ao seu arquivo CSS */
@media (max-width: 768px) {
    .filter-mobile {
        display: none;
    }
}
