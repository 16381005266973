/* Custom scrollbar styles */
.scrollbar-custom::-webkit-scrollbar {
    width: 12px;
}

.scrollbar-custom::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
    background-color: #ffa500; /* Cor laranja */
    border-radius: 6px;
    border: 3px solid #f1f1f1;
}

.scrollbar-custom::-webkit-scrollbar-thumb:hover {
    background-color: #ff8c00;
}
