.scrollToTopButton {
    position: fixed;
    bottom: 40px; /* ou 1rem, equivalente a 'bottom-4' no Tailwind */
    right: 16px; /* ou 1rem, equivalente a 'right-4' no Tailwind */
    background: linear-gradient(to top right,rgba(190,64,38,.8),rgba(247,180,23,.8));
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
    color: white;
    padding: 8px 16px; /* Similar a 'py-2 px-4' */
    border-radius: 20px; /* Similar a 'rounded' */
    font-weight: bold;
    cursor: pointer;
    z-index: 50;
    opacity: 0; /* inicia invisível */
    pointer-events: none; /* desativa eventos de mouse quando está invisível */
    transition: opacity 0.3s ease-in-out; /* transição suave da opacidade */
}

.scrollToTopButton.visible {
    opacity: 1; /* totalmente visível */
    pointer-events: auto; /* ativa eventos de mouse quando está visível */
}

.scrollToTopButton:hover {
    background: linear-gradient(to top right,rgba(190,64,38,.9),rgba(247,180,23,.9));
}

.scrollToTopButton:focus {
    outline: none;
}