
/* Estiliza a scrollbar (para navegadores baseados em WebKit) */
.sidebar-menu::-webkit-scrollbar {
    width: 10px; /* Largura da scrollbar */
}

/* Estiliza a trilha da scrollbar */
.sidebar-menu::-webkit-scrollbar-track {
    background: #f9f9f9; /* Cor de fundo da trilha */
    border-radius: 10px; /* Arredondar as bordas da trilha */
}

/* Estiliza o manipulador da scrollbar */
.sidebar-menu::-webkit-scrollbar-thumb {
    background: #ddd; /* Cor do manipulador */
    border-radius: 10px; /* Arredondar as bordas do manipulador */
}

/* Estilo do manipulador ao passar o mouse */
.sidebar-menu::-webkit-scrollbar-thumb:hover {
    background: #ccc; /* Cor do manipulador ao passar o mouse */
}

.sidebar-menu {
    z-index: 1000; /* Um valor alto para garantir que esteja acima de outros conteúdos */
}
