/* Spinner.css */
.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.spinner-icon {
    animation: float 2s infinite ease-in-out;
}

@keyframes float {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
}

@media (max-width: 600px) {
    .spinner-icon {
        width: 10vw;
        height: 10vw;
    }
}

@media (min-width: 601px) and (max-width: 1200px) {
    .spinner-icon {
        width: 7vw;
        height: 7vw;
    }
}

@media (min-width: 1201px) {
    .spinner-icon {
        width: 5vw;
        height: 5vw;
    }
}
