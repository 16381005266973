/* Esconde a barra lateral em telas menores por padrão */
/* Esconde o botão de hambúrguer em telas grandes */

/* .hamburger-button {
    display: none ;
} */

/* Em telas maiores, mostra a barra lateral */
@media screen and (min-width: 768px) {
    .sidebar-menu {
        display: block;
    }


    .menu-button {
        display: none !important;
    }

    .login-menu {
        position: absolute; /* ou 'fixed', dependendo do layout desejado */
        right: 20px; /* Ajuste a posição conforme necessário */
        top: 10px; /* Ajuste a posição conforme necessário */
    }
}

.menu-button {
    display: block ;
}
