/* SelectOptionsLogin.css */

/* Estilos para dispositivos móveis */
@media (max-width: 768px) {
    .fixed-logo {
        position: fixed;
        top: 0;
        left: 0;
        padding: 4px;
    }
    .fixed-logo img {
        width: 8rem;
        height: 8rem;
        margin-right: 1rem;
    }
    .fixed-logo h1 {
        font-size: 1.2rem;
        margin-top: 0;
    }
    .container-mobile {
        flex-direction: column;
        height: auto !important;
        margin-top: 6rem !important;
    }
    .container-mobile-2 {
        flex-direction: column;
        height: auto !important;
        margin-top: 0 !important;
        align-items: center;
    }
    .card-mobile {
        width: 90% !important;
        margin-bottom: 1rem;
    }
    .text-base {
        font-size: 0.8rem;
    }
    .text-xl {
        font-size: 1rem;
    }
    .text-2xl {
        font-size: 1.2rem;
    }
    .bg-gray-50 {
        text-align: center;
    }
    .bg-gray-50 button {
        width: 100%;
        margin-top: 1rem;
    }
}


